import fuzzysort from 'fuzzysort';
import {useStarcarDateRangePickerStateContext} from 'hooks/BPR/useStarcarDateRangePickerState';
import useOutsideAlerter from 'hooks/useOutsideAlerter';
import {useCallback, useEffect, useRef, useState} from 'react';
import {Control} from 'react-hook-form';

import {Box} from '@mui/material';

import {Station} from 'lib/api/backend.schemas';

import {TMergedErrors} from 'utils/forms/mergeErrors';

import {withErrorBoundary} from 'components/error/ErrorBoundary';

import FormInputStation from '../FormFields/FormInputStation';
import StationSelectionFlyout from './StationSelectionFlyout';
import {IStationWithDistance} from './types';
import React from 'react';

const createFuzzyMatcher =
  ({key, keywords}: {key: keyof Station; keywords: string[]}) =>
  station =>
    station?.[key] ? keywords.join(' ') : '';
const StationSelection = ({
  name,
  control,
  disabled,
  errors,
  selectedStation,
  setStation,
  isBPRActive = true,
  setStationTextField,
  stations = [],
  value = '',
  isWidget = false,
  showPopularStations = false,
  onFocus = () => {},
  isOneWay = false,
  getAvailableReturnStations,
  isLoadingReturnStations,
  setOneWayStationsDistance = () => {},
  setOneWayStationsDistanceText = () => {},
  stationFlyoutAnchorLeft,
  setStationFlyoutAnchorLeft = () => {},
}: {
  name: string;
  control: Control<any, any>;
  disabled?: boolean;
  errors: TMergedErrors;
  isBPRActive?: boolean;
  selectedStation?: IStationWithDistance;
  setStation: (station: IStationWithDistance) => void;
  setStationTextField: (value: string) => void;
  stations: Station[];
  value: string;
  isWidget: boolean;
  showPopularStations: boolean;
  onFocus?: () => void;
  isOneWay: boolean;
  isLoadingReturnStations?: boolean;
  getAvailableReturnStations: (station?: string) => void;
  setOneWayStationsDistance?: ({station, returnStation, distance}: {station?: string, returnStation?: string, distance?: string}) => void;
  setOneWayStationsDistanceText?: (value: string) => void;
  stationFlyoutAnchorLeft?: number;
  setStationFlyoutAnchorLeft?: (value: number) => void;
}) => {
  const [open, setOpen] = useState(false);
  const [gmRadiusStations, setGmRadiusStations] = useState([] as IStationWithDistance[]);
  const [hoveredStation, setHoveredStation] = useState(null as IStationWithDistance | null);

  const {isDesktop, formMethods} = useStarcarDateRangePickerStateContext();

  const filterStationsBasedOnInputValue = () => {
    if (!value)
      return showPopularStations ? stations
        .filter(station => station.popularStation)
        .sort((a, b) => a.popularOrder - b.popularOrder) : stations;
    const lowerCaseValue = value.toLowerCase();
    const result = fuzzysort.go(lowerCaseValue, stations, {
      keys: [
        'city',
        'description',
        'zip',
        'name',
        createFuzzyMatcher({key: 'airportStation', keywords: ['airport', 'flughafen']}),
        createFuzzyMatcher({
          key: 'airportPickupService',
          keywords: ['pickup', 'abholen'],
        }),
        createFuzzyMatcher({
          key: 'lateNightService',
          keywords: ['lateNightService', 'spät'],
        }),
      ],
    });
    return result.map(res => res?.obj);
  };

  const filteredStations = gmRadiusStations.length
    ? gmRadiusStations
    : filterStationsBasedOnInputValue();

  useEffect(() => {
    if (!stations || stations.length === 0) {
      setHoveredStation(null);
    }
  }, [stations]);

  const [hasFocus, setHasFocus] = useState(false);
  const componentRef = useRef<HTMLDivElement>();
  const alerterFunction = useCallback(() => {
    setOpen(false);
  }, []);
  useEffect(() => {
    if (!isBPRActive) {
      setOpen(false);
    }
  }, [isBPRActive]);
  useOutsideAlerter(componentRef, alerterFunction, ['.close-bpr-modal-button']);
  useEffect(() => {
    if (hasFocus && !disabled && isWidget && isDesktop) {
      setTimeout(() => {
        setOpen(true);
      }, 300);
    } else if (hasFocus && !disabled) {
      setOpen(true);
    }
  }, [value, hasFocus, disabled]);

  const hoveredStationDown = () => {
    if (!hoveredStation) {
      return;
    }
    filteredStations.find((station, index) => {
      if (hoveredStation.id === station.id) {
        const newIndex = index + 1 >= filteredStations.length ? 0 : index + 1;

        setHoveredStation(filteredStations[newIndex]);
        return true;
      } else {
        return false;
      }
    });
  };
  const hoveredStationUp = () => {
    if (!hoveredStation) {
      return;
    }
    filteredStations.find((station, index) => {
      if (hoveredStation.id === station.id) {
        const newIndex = index - 1 < 0 ? filteredStations.length - 1 : index - 1;
        setHoveredStation(filteredStations[newIndex]);
        return true;
      } else {
        return false;
      }
    });
  };
  const keyDownHandler = useCallback(
    e => {
      switch (e.key) {
        case 'Escape':
          setStationTextField('');
          setStation(null);
          setOpen(false);
          break;
        case 'Enter':
          e.preventDefault();
          e.stopPropagation();
          if (hoveredStation) {
            setStation(hoveredStation);
            setStationTextField(hoveredStation.description);
            setOpen(false);
            setHasFocus(false);
            input.current && input.current?.blur();
          }
          break;
        case 'ArrowDown':
          hoveredStationDown();
          break;
        case 'ArrowUp':
          hoveredStationUp();
          break;
        default:
          break;
      }
    },
    [setStationTextField],
  );

  const input = useRef<HTMLInputElement>();
  const setFocusOninput = () => {
    input.current && input.current?.focus();
  };

  const {setCalendarOpen, resetField, setValue, setSelectedTimeField} =
    useStarcarDateRangePickerStateContext();

  useEffect(() => {
    if (componentRef?.current && !name.includes('returnStation')) {
      setStationFlyoutAnchorLeft(componentRef?.current?.getBoundingClientRect().left);
    }
  }, [componentRef?.current]);

  return (
    <Box onKeyDown={keyDownHandler} onFocus={onFocus} ref={componentRef}>
      <FormInputStation
        name={name}
        control={control}
        disabled={disabled}
        inputRef={input}
        isWidget={isWidget}
        isOneWay={isOneWay}
        onClear={
          !disabled
            ? () => {
                setStationTextField('');
                setStation(null);
                setOpen(false);
                setCalendarOpen(false);
                if (name.includes('returnStation')) {
                  setValue('returnStation', '');
                  setValue('returnStationTextField', '');
                } else {
                  setValue('station', '');
                  setValue('stationTextField', '');
                  setValue('returnStation', '');
                  setValue('returnStationTextField', '');
                }
                resetField('departure');
                resetField('arrival');
                setValue('departureTime', null);
                setValue('arrivalTime', null);
                setTimeout(() => {
                  // clear errors on departure and arrival after resetting datepicker
                  formMethods.clearErrors(['departure', 'arrival', 'arrivalTime', 'departureTime'])
                }, 100)
                setOneWayStationsDistanceText('');
                if (isOneWay) setValue('distance', '');
              }
            : null
        }
        onFocus={() => {
          if (!open) {
            // setGmRadiusStations([]);
            setHasFocus(true);
            setCalendarOpen(false);
            setSelectedTimeField(null);
          }
        }}
        onBlur={() => {
          setHasFocus(false);
        }}
        errors={errors}
      />
      <Box sx={{display: open ? 'block' : 'none'}}>
        {open ? (
          <StationSelectionFlyout
            name={name}
            gmRadiusStations={gmRadiusStations}
            filteredStations={filteredStations}
            hoveredStation={hoveredStation}
            isOpen={open}
            setFocusOninput={setFocusOninput}
            setGmRadiusStations={setGmRadiusStations}
            setHoveredStation={setHoveredStation}
            setOpen={setOpen}
            setStation={setStation}
            setStationTextField={setStationTextField}
            stations={stations}
            value={value}
            valueIsStation={selectedStation && value === selectedStation.description}
            isWidget={isWidget}
            showPopularStations={showPopularStations}
            isOneWay={isOneWay}
            isLoadingReturnStations={isLoadingReturnStations}
            getAvailableReturnStations={getAvailableReturnStations}
            setOneWayStationsDistance={setOneWayStationsDistance}
            anchorLeft={stationFlyoutAnchorLeft}
          />
        ) : null}
      </Box>
    </Box>
  );
};

export default withErrorBoundary(StationSelection, 'StationSelection');
