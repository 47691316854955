import {useTranslation} from 'hooks/translations/useCustomTranslation';
import Image from 'next/image';
import {useContext} from 'react';
import {Controller, useForm} from 'react-hook-form';

import {Checkbox, FormControlLabel} from '@mui/material';

import {GOOGLE_PRIVACY_POLICIES} from 'utils/maps/loadMaps';

import {ConsentContext} from 'components/section-components/ConsentManager';

import Box from '../Box';
import {Button} from '../Button';
import {NextLink} from '../NextLink/Link';
import Typography from '../Typography';
import styles from './MapConsentOverlay.module.scss';
import mapConsentBg from 'public/assets/map_consent_bg.png';

export const MapsConsentOverlay = ({onSetVisible, height = 420, width = 397}) => {
  const {t} = useTranslation(['bpr', 'common']);

  const {
    state: {maps},
    setConsentStateForKey,
  } = useContext(ConsentContext);
  const {control, handleSubmit} = useForm({
    defaultValues: {mapsAccepted: maps},
  });
  const onButtonClick = handleSubmit(({mapsAccepted}) => {
    if (mapsAccepted) {
      setConsentStateForKey('maps', true);
    } else {
      onSetVisible(true);
    }
  });
  return (
    <Box className={styles.consentOverlay}>
      <Box className={styles.consentOverlay_content}>
        <Typography className={styles.title}>{t('displayMap?')}</Typography>
        <Typography className={styles.infoText}>{t('mapsInfoText')}</Typography>
        <Typography className={styles.linkText}>
          <NextLink
            style={{color: 'inherit'}}
            href={GOOGLE_PRIVACY_POLICIES}
            target="_blank"
            rel="noreferrer"
          >
            {t('learnMore', {ns: 'common'})}
          </NextLink>
        </Typography>
        <Box className={styles.button}>
          <Button onClick={onButtonClick} variant="inverted">
            {t('displayMap')}
          </Button>
        </Box>
        <Box>
          <Controller
            name="mapsAccepted"
            control={control}
            render={({field: {name, onChange, value}}) => {
              return (
                <FormControlLabel
                  control={
                    <Checkbox
                      name={name}
                      onChange={onChange}
                      checked={value}
                      sx={{'& *': {color: 'black'}}}
                    />
                  }
                  label={t('alwaysShowMap')}
                />
              );
            }}
          />
        </Box>
      </Box>
      <Box className={styles.backgroundOverlay} />
      <Image
        className={styles.bg}
        alt=""
        priority
        height={height}
        width={width}
        src={`${mapConsentBg.src}`}
      ></Image>
    </Box>
  );
};

export default MapsConsentOverlay;
