import * as yup from 'yup';

import {CalendarDate} from '@internationalized/date';

const yupSchema = t => {
  const stationValidation: any = ([stationTextField], schema: any, value: any) => {
    if (!value.length && !stationTextField?.length) {
      return schema.required(t('selectStation', {ns: 'bpr'}));
    }
    if (stationTextField?.length) {
      return schema.required(t('selectStation', {ns: 'bpr'}));
    }
  };
  const oneWayValidation: any = ([oneWay], schema) =>{
    return oneWay === true
      ? schema.required(t('selectReturnStation', { ns: 'bpr' }))
      : schema
  }
  const schema = yup.object({
    category: yup.string(),
    stationTextField: yup.string().required(t('selectStation', {ns: 'bpr'})),
    // oneWay: yup.boolean().optional().nullable,
    returnStationTextField: yup.string().default('').when(['oneWay'], oneWayValidation),
    returnStation: yup.string().default('').when(['oneWay'], oneWayValidation),
    group: yup.string(),
    vehicle: yup.string().nullable(),
    departureTime: yup.string().required(t('selectDepartureTime', {ns: 'bpr'})),
    arrivalTime: yup.string().required(t('selectArrivalTime', {ns: 'bpr'})),
    station: yup
      .string()
      .required(t('selectStation', {ns: 'bpr'}))
      .when(['stationTextField'] as string[], stationValidation),
    departure: yup
      .mixed<CalendarDate>()
      .nullable()
      .required(t('selectRentalStart', {ns: 'bpr'})).test(
    'departure',
    t('rentalDateErrorText', { ns: 'bpr' }), // Custom error message
    function () {
      const { departure, arrival, departureTime, arrivalTime } = this.parent;
      if (!departure || !arrival || !departureTime || !arrivalTime) {
        return true; // Skip validation if any of these are not set
      }

      const departureDateTime = new Date(
        `${departure.toString()} ${departureTime}`
      );

      const arrivalDateTime = new Date(
        `${arrival.toString()} ${arrivalTime}`
      );

      // Check if the departure datetime is after the arrival datetime
      return departureDateTime <= arrivalDateTime;
    }
  ),
    arrival: yup
      .mixed<CalendarDate>()
      .nullable()
      .required(t('selectRentalEnd', {ns: 'bpr'})).test(
    'departure',
    t('rentalDateErrorText', { ns: 'bpr' }), // Custom error message
    function () {
      const { departure, arrival, departureTime, arrivalTime } = this.parent;

      if (!departure || !arrival || !departureTime || !arrivalTime) {
        return true; // Skip validation if any of these are not set
      }

      const departureDateTime = new Date(
        `${departure.toString()} ${departureTime}`
      );

      const arrivalDateTime = new Date(
        `${arrival.toString()} ${arrivalTime}`
      );

      // Check if the departure datetime is after the arrival datetime
      return departureDateTime <= arrivalDateTime;
    }
  ),
    distance: yup.string().when([], () => {
      return yup
        .number()
        .transform(value => (isNaN(value) ? undefined : value))
        .typeError(t('numberShouldBeNumeric', {ns: 'bpr'}))
        .notRequired()
        .nullable()
        .min(1, t('numberShouldBeAtLeast1', {ns: 'bpr'}))
        .max(99999, t('numberShouldBeMax99999', {ns: 'bpr'}));
    }),
    oneWay: yup.boolean().required(),
  });
  return schema;
};

export default yupSchema;
