/* eslint-disable @next/next/no-img-element */
import {useContext, useState} from 'react';

import {withErrorBoundary} from 'components/error/ErrorBoundary';
import {ConsentContext} from 'components/section-components/ConsentManager';

import {GOOGLE_MAPS_STATIC_API_KEY} from '../../../utils/maps/loadMaps';
import MapsConsentOverlay from '../MapsConsentOverlay';
import {IGoogleMapsImage} from './types';

const GoogleMapsImage = ({
  width,
  height,
  markers,
  lat,
  lng,
  alt = '',
  secondMarkers,
  secondLat,
  secondLng,
  polyline,
}: IGoogleMapsImage) => {
  const BASE_URL = 'https://maps.googleapis.com/maps/api/staticmap';
  const ZOOM_LEVEL = '14';
  const MAPTYPE = 'roadmap';

  let secondMarker =
    secondMarkers && secondLat && secondLng && polyline
      ? `&markers=icon:${secondMarkers}|${secondLat},${secondLng}&path=color:0x0000ff|enc:${encodeURIComponent(polyline)}`
      : '';

  const {
    state: {maps},
  } = useContext(ConsentContext);

  const [isVisible, setIsVisible] = useState(maps);

  return !(maps || isVisible) ? (
    <MapsConsentOverlay onSetVisible={setIsVisible} height={height} width={width} />
  ) : (
    <img
      alt={alt}
      height={height}
      width={width}
      src={`${BASE_URL}?zoom=${ZOOM_LEVEL}&size=${width}x${height}&maptype=${MAPTYPE}&scale=2&markers=icon:${markers}|${lat},${lng}${secondMarker}&key=${GOOGLE_MAPS_STATIC_API_KEY}`}
    />
  );
};

export default withErrorBoundary(GoogleMapsImage, 'GoogleMapsImage');
