import {useTranslation} from 'hooks/translations/useCustomTranslation';
import React, {CSSProperties} from 'react';

import {Station} from 'lib/api/backend.schemas';

import {formatDayMonthDate} from 'utils/date/formatDate';

import {palette} from 'styles/mui/scss';

import {Box, Typography} from 'components/basic-components';
import {CustomIcon} from 'components/basic-components/CustomIcon/CustomIcon';

import {NextLink} from '../NextLink/Link';
import styles from './stationLabels.module.scss';

const StationLabel = ({
  label,
  backgroundColor,
  textColor,
  link,
  icon,
}: {
  label: string;
  backgroundColor: string;
  textColor: 'black' | 'white';
  link?: string;
  icon: string;
}) => {
  return (
    <Box sx={{backgroundColor: backgroundColor, color: textColor}} className={styles.labelWrapper}>
      {link ? (
        <NextLink href={link} className={styles.link}>
          <CustomIcon name={icon} className={styles.icon} />
          <Typography className={styles.labelText}>{label}</Typography>
        </NextLink>
      ) : (
        <>
          <CustomIcon name={icon} className={styles.icon} />
          <Typography className={styles.labelText}>{label}</Typography>
        </>
      )}
    </Box>
  );
};

const StationLabels = ({station, sx}: {station: Station; sx?: CSSProperties}) => {
  const {t} = useTranslation('labels');

  const date = new Date();
  let stationOpeningDate = new Date();

  if (station.openingDate) {
    stationOpeningDate = new Date(station.openingDate);
  }

  const labels = [];

  if (station.partner) {
    labels.push(
      <StationLabel
        key="partner"
        label={t('starcarPartner')}
        backgroundColor={palette.colorPrimary}
        textColor="black"
        icon="star"
      />,
    );
  }

  if (station.remoteStation) {
    labels.push(
      <StationLabel
        key="remote"
        label={t('remoteStation')}
        backgroundColor="#3e4fa5"
        textColor="white"
        icon="keyAlt"
      />,
    );
  }

  if (station.airportPickupService) {
    labels.push(
      <StationLabel
        key="airportPickup"
        label={t('airportPickup')}
        backgroundColor="#ce6c9e"
        textColor="white"
        icon="plane"
      />,
    );
  }
  if (station.pickupStation) {
    labels.push(
      <StationLabel
        key="pickupStation"
        label={t('pickupStation')}
        backgroundColor="#3e4fa5"
        textColor="white"
        icon="keyAlt"
      />,
    );
  }
  if (station.airportStation) {
    labels.push(
      <StationLabel
        key="airportStation"
        label={t('airportStation')}
        backgroundColor="#ce6c9e"
        textColor="white"
        icon="plane"
      />,
    );
  }
  if (station.trainPickupService) {
    labels.push(
      <StationLabel
        key="centralStationPickup"
        label={t('centralStationPickup')}
        backgroundColor={'#a14141'}
        textColor="white"
        icon="train"
      />,
    );
  }
  if (station.lateNightService) {
    labels.push(
      <StationLabel
        key="lateNightStation"
        label={t('lateNightStation')}
        backgroundColor={'#50829e'}
        textColor="white"
        icon="moon"
      />,
    );
  }
  if (station.ecompetence) {
    labels.push(
      <StationLabel
        key="eCompotenceService"
        label={t('eCompetenceService')}
        backgroundColor={'#559d3d'}
        textColor="white"
        icon="lightning"
      />,
    );
  }
  if (station.licensee) {
    labels.push(
      <StationLabel
        key="licenseHolder"
        label={t('licenseHolder')}
        backgroundColor={palette.colorPrimary}
        textColor="black"
        icon="star"
      />,
    );
  }
  if (station.openingDate && date.getTime() < stationOpeningDate.getTime()) {
    labels.push(
      <StationLabel
        key="newStation"
        label={`${t('newFrom')} ${formatDayMonthDate(stationOpeningDate)}`}
        backgroundColor={'#e84747'}
        textColor="white"
        link="/coming-soon"
        icon="starAlt"
      />,
    );
  }

  return (
    <Box sx={sx} className={labels.length > 0 ? styles.labelsWrapper : styles.noLabels}>
      {labels}
    </Box>
  );
};

export default StationLabels;
