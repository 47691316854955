import {useTranslation} from 'hooks/translations/useCustomTranslation';

import {Box, Typography} from '@mui/material';

import GoogleMapsImage from 'components/basic-components/GoogleMapsImage';
import StationLabels from 'components/basic-components/StationLabels';
import {withErrorBoundary} from 'components/error/ErrorBoundary';

import StationInformation from './StationInformation';
import styles from './styles/stationCard.module.scss';
import {IStationWithDistance} from './types';

const StationCard = ({station}: {station: IStationWithDistance}) => {
  const {t} = useTranslation(['bpr', 'common']);
  if (!station) return null;

  return (
    <Box className={`stationInformation ${styles.wrapper}`}>
      <Typography variant="footerCards" className={styles.stationName}>
        {station.description}
      </Typography>
      <StationInformation station={station} />
      <Box sx={{height: '24px', marginBottom: '25px'}}>
        <StationLabels station={station} />
      </Box>
      <Box className={styles.map}>
        <GoogleMapsImage
          key={`${station.id}`}
          alt={t('stationMap')}
          height={349}
          width={550}
          markers={`https://minio.starcar.de/strapi/icon_map_marker_cf4f297012.png`}
          lat={station.latitude}
          lng={station.longitude}
          secondMarkers={
            station.remoteStation
              ? 'https://minio.starcar.de/strapi/icon_map_marker_cf4f297012.png'
              : ''
          }
          secondLat={station.relatedStation?.latitude}
          secondLng={station.relatedStation?.longitude}
          polyline={station.relatedStationPolyline}
        />
      </Box>
    </Box>
  );
};

export default withErrorBoundary(StationCard, 'StationCard');
