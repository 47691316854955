import {useTranslation} from 'hooks/translations/useCustomTranslation';

import useMediaQuery from '@mui/material/useMediaQuery';
import {visuallyHidden} from '@mui/utils';

import {breakpoint} from 'styles/mui/scss';

import {Button} from 'components/basic-components';
import {CustomIcon} from 'components/basic-components/CustomIcon/CustomIcon';

import styles from './styles/formButtonSubmit.module.scss';

const ButtonSubmit = ({
  opacity = 1,
  isWidget = false,
  layout,
}: {
  opacity?: Number;
  isWidget?: boolean;
  layout?: string;
}) => {
  const [t] = useTranslation('bpr');
  const isDesktop = useMediaQuery(`(min-width:${breakpoint.lg}px)`);

  if (isDesktop && !isWidget) {
    return (
      <Button
        style={{opacity: `${opacity}`}}
        className={styles.submitButton}
        sx={{
          '& .MuiButton-endIcon': {
            margin: 0,
          },
        }}
        endIcon={<CustomIcon name="magnifyingglass" />}
        type="submit"
      >
        <span style={visuallyHidden}>{t('search')}</span>
      </Button>
    );
  }
  return (
    <Button
      style={{opacity: `${opacity}`}}
      sx={{
        padding: layout === 'klm' ? null : '18px 20px',
        width: layout === 'klm' ? 'auto' : '100%',
        marginLeft: layout === 'klm' ? 'auto' : null,
        marginRight: {xxl: layout === 'klm' ? '10px' : null},
        '& .MuiButton-endIcon': {
          margin: 0,
        },
      }}
      startIcon={layout === 'klm' ? null : <CustomIcon name="magnifyingglass" />}
      type="submit"
    >
      {layout === 'klm' ? t('further') : t('viewOffer')}
    </Button>
  );
};
export default ButtonSubmit;
