import EmergencyContext from 'context/EmergencyContext';
import {useStarcarDateRangePickerState} from 'hooks/BPR/useStarcarDateRangePickerState';
import {useForm} from 'hooks/custom-react-hook-form';
import usePrevious from 'hooks/usePrevious';
import {useTranslation} from 'hooks/translations/useCustomTranslation';
import {useRouter} from 'next/router';
import {useContext, useEffect, useRef, useState} from 'react';
import {DateValue} from 'react-aria';

import {yupResolver} from '@hookform/resolvers/yup';
import {CalendarDate, parseDateTime} from '@internationalized/date';

import {
  getApiV1GetOneWayDistance,
  getApiV1GetOneWayReturnStations,
  useGetApiV1GetBestpriceConfig,
  usePostApiV1CreateReservation
} from 'lib/api/backend';

import {formatTime} from 'utils/date/formatDate';
import mergeErrors from 'utils/forms/mergeErrors';

import {ConsentContext} from 'components/section-components/ConsentManager';

import {BPRWidgetProps} from '../../components/section-components/bpr/BPRWidget/BPRWidget';
import yupSchema from '../../components/section-components/bpr/getSchema';
import {useStations} from './useStations';
import {useGetTranslatedCategory} from "hooks/useGetTranslatedCategory";
import {Station} from "lib/api/backend.schemas";

export interface IUseBestpriceRechnerProps extends BPRWidgetProps {
  isWidget?: boolean;
  minValue?: DateValue;
  maxValue?: DateValue;
}

export const useBestPriceRechner = ({
  preferredVehicle: vehicle,
  presetStation,
  presetReturnStation,
  presetCategory,
  presetGroup,
  presetType,
  presetDistance,
  withSessionConfig = true,
  isWidget,
  maxRentalDuration,
  minValue,
  maxValue,
  isHeaderBPR,
  origin = 'bestprice-normal',
  layout = 'base',
  isTeaser = false,
}: IUseBestpriceRechnerProps) => {
  const {t} = useTranslation('bpr');
  const schema = yupSchema(t);
  const bprRef = useRef<HTMLFormElement>();

  const {
    push,
    query: {reservationId: pathReservationId},
  } = useRouter();

  const {
    isSuccess: isSuccessStations,
    stationsById,
    stations = [],
    oneWayStationsCar = [],
    oneWayStationsTruck = []
  } = useStations(layout !== 'klm' && presetGroup ? presetGroup : null);

  const {
    isLoading: isBestpriceLoading,
    isSuccess: isSuccessBestprice,
    data: bestpriceConfig,
  } = useGetApiV1GetBestpriceConfig(
    pathReservationId ? {reservationId: pathReservationId as string} : {},
  );

  const {
    isSuccess: isCreateReservationSuccess,
    data: {id: reservationId, affiliation, distance: reservationDistance} = {},
    mutate: createReservationMutate,
    error: createReservationErrors,
  } = usePostApiV1CreateReservation({
    mutation: {onError: async err => err},
  });

  const requestErrors =
    (createReservationErrors?.response?.data && createReservationErrors?.response?.data?.errors) ||
    {};

  const {pushDataLayer} = useContext(ConsentContext);
  useEffect(() => {
    if (isCreateReservationSuccess && reservationId) {
      if (!presetGroup) {
        pushDataLayer({
          event: 'bestprice',
          station: fieldValues.station,
          category: fieldValues.category,
          kilometer: fieldValues.distance || reservationDistance,
          affiliation: affiliation,
          step: '1'
        });
        push(`/reservation/${reservationId}/step1`);
      } else {
        pushDataLayer({
          event: 'bestprice',
          station: fieldValues.station,
          category: fieldValues.category,
          kilometer: fieldValues.distance || reservationDistance,
          affiliation: affiliation,
          step: '2'
        });
        push(`/reservation/${reservationId}/step2`);
      }
    }
  }, [isCreateReservationSuccess, reservationId, affiliation, presetGroup, push]);

  const formMethods = useForm({
    name: 'BestPriceRechner',
    defaultValues: {
      arrival: null,
      category: (presetCategory || t('bestpriceCategoryCar')) as string,
      departure: null,
      distance: presetDistance || '',
      departureTime: null,
      arrivalTime: null,
      group: presetGroup || '',
      station: presetStation || '',
      stationTextField: '',
      returnStation: '',
      returnStationTextField: '',
      oneWay: false,
      vehicle,
    },
    resolver: yupResolver(schema),
  });

  const {
    clearErrors,
    control,
    formState: {errors: formErrors, isDirty},
    handleSubmit,
    reset,
    setValue,
    watch,
    setError,
  } = formMethods;

  const onSubmit = async formData => {
    const originPrefix = formData.oneWay ? 'oneway_' : 'bpr_';
    const res = {
      ...formData,
      returnStation: formData.oneWay ? formData.returnStation : '',
      arrival: `${formData.arrival.year}-${formData.arrival.month}-${formData.arrival.day} ${formData.arrivalTime}`,
      category: presetCategory || formData.category,
      departure: `${formData.departure.year}-${formData.departure.month}-${formData.departure.day} ${formData.departureTime}`,
      distance: formData.distance ? Number(formData.distance) : '',
      group: presetGroup || formData.group,
      type: presetType || null,
      origin: presetStation ?
        `${originPrefix}station`
        : presetGroup && isTeaser ?
          `${originPrefix}cts`
          : presetGroup ?
            `${originPrefix}tarife`
            : isWidget ?
              `${originPrefix}widget`
              : `${originPrefix}header`,
      vehicle: vehicle || formData.vehicle,
    };
    createReservationMutate({data: res});
  };

  const errors = mergeErrors(formErrors as any, requestErrors);
  const fieldValues = watch();
  const {emergencyOnPointerDownHandler} = useContext(EmergencyContext);

  useEffect(() => {
    if ((withSessionConfig || !withSessionConfig) && isSuccessStations && presetStation) {
      setValue('stationTextField', stationsById[presetStation].description);
      setValue('station', presetStation);
    }
    if ((withSessionConfig || !withSessionConfig) && isSuccessStations && presetReturnStation) {
      setValue('oneWay', presetStation !== presetReturnStation);
      setValue('returnStationTextField', stationsById[presetReturnStation].description);
      setValue('returnStation', presetReturnStation);
    }
  }, [presetStation, isSuccessBestprice, isSuccessStations, withSessionConfig, presetReturnStation]);

  const previousBestPriceConfigDistance = usePrevious(bestpriceConfig?.distance);

  const starcarDateRangePickerState = useStarcarDateRangePickerState({
    props: {isWidget, minValue, maxValue, maxRentalDuration, defaultOpen: !!presetStation, presetStation: presetStation ? stationsById[presetStation] || null : null,  isHeaderBPR},
    formMethods,
    stationsById,
    bprRef,
  });

  const {
    isDesktop,
    setCalendarOpen,
    resetDatepicker,
    resetFormValues,
    state: {setDateRange},
  } = starcarDateRangePickerState;
  useEffect(() => {
    if (
      bestpriceConfig &&
      isSuccessBestprice &&
      isSuccessStations &&
      !isDirty &&
      withSessionConfig
    ) {
      const {station, returnStation, departure, arrival, category, distance, oneWay} = bestpriceConfig;
      const dateRange = {start: null as CalendarDate, end: null as CalendarDate};
      if (station && departure && arrival && category && distance) {
        reset({
          station,
          stationTextField: stationsById[station]?.description || '',
          returnStation,
          returnStationTextField: returnStation ? stationsById[returnStation]?.description || '' : '',
          category,
          distance: `${distance}`,
          oneWay
        });

        if (station && returnStation && (station !== returnStation)) {
          setOneWayStationsDistance({station: station, returnStation: returnStation, distance: `${distance}`});
        }
      }

      if (bestpriceConfig.departure) {
        setTimeout(() => {
          const departureDate = parseDateTime(bestpriceConfig.departure);
          dateRange.start = new CalendarDate(
            departureDate.year,
            departureDate.month,
            departureDate.day,
          );
          setValue('departure', dateRange.start, {
            shouldTouch: true,
            shouldDirty: false,
          });
          setValue(
            'departureTime',
            `${departureDate.hour.toString().padStart(2, '0')}:${departureDate.minute
              .toString()
              .padStart(2, '0')}`,
            {
              shouldTouch: true,
              shouldDirty: false,
            },
          );
        }, 50);
      }

      if (bestpriceConfig.arrival) {
        setTimeout(() => {
          const arrivalDate = new Date(bestpriceConfig.arrival);
          dateRange.end = new CalendarDate(
            arrivalDate.getFullYear(),
            arrivalDate.getMonth() + 1,
            arrivalDate.getDate(),
          );
          setValue('arrival', dateRange.end, {
            shouldTouch: true,
            shouldDirty: false,
          });
          setValue('arrivalTime', formatTime(arrivalDate), {
            shouldTouch: true,
            shouldDirty: false,
          });
        }, 50);
      }
    }

    if (
      bestpriceConfig?.distance &&
      previousBestPriceConfigDistance &&
      bestpriceConfig?.distance !== previousBestPriceConfigDistance
    ) {
      setTimeout(
        () =>
          setValue('distance', `${bestpriceConfig.distance}`, {
            shouldTouch: true,
            shouldDirty: true,
          }),
        30,
      );
    }
  }, [isSuccessBestprice, bestpriceConfig, isBestpriceLoading, isSuccessStations, isDirty]);

  useEffect(() => {
    const {
      station,
      stationTextField,
      returnStation,
      returnStationTextField,
      departure,
      departureTime,
      arrival,
      arrivalTime,
      category,
      distance,
      oneWay
    } = fieldValues;
    if ((station || returnStation) && departure && departureTime && arrival && arrivalTime && category && distance) {
      reset({
        station,
        stationTextField,
        returnStation,
        returnStationTextField,
        departure,
        departureTime,
        arrival,
        arrivalTime,
        category,
        distance: `${distance}`,
        oneWay
      }, {keepTouched: true, keepDirty: true});
    }
  }, [isDesktop]);

  const getTranslatedCategory = useGetTranslatedCategory();
  const [filteredOneWayStations, setFilteredOneWayStations] = useState(stations);
  const [filteredOneWayReturnStations, setFilteredOneWayReturnStations] = useState([] as Station[]);
  const [oneWayStationsDistanceText, setOneWayStationsDistanceText] = useState('');
  const [isLoadingReturnStations, setIsLoadingReturnStations] = useState(false);
  const getAvailableReturnStations = async (station?: string) => {
    setIsLoadingReturnStations(true);
    if (fieldValues.oneWay && (station || fieldValues.station) && fieldValues.category && !presetReturnStation) {
      setFilteredOneWayReturnStations([]);
      const response = await getApiV1GetOneWayReturnStations({
        station: station || fieldValues.station,
        category: fieldValues.category,
      }, {noSpinner: true}).catch(err => setIsLoadingReturnStations(false));
      setIsLoadingReturnStations(false)
      setFilteredOneWayReturnStations(response as any);
    }
  };

  const setOneWayStationsDistance = async ({station, returnStation, distance}: {station?: string, returnStation?: string, distance?: string} = {}) => {
    if ((!!fieldValues.station || !!station) && (!!fieldValues.returnStation || !!returnStation)) {
      const params = {
        originStation: station || fieldValues.station,
        destinationStation: returnStation || fieldValues.returnStation,
      };
      getApiV1GetOneWayDistance(params, {noSpinner: true}).then(res => {
        const {distance: stationDistance} = res;
        setOneWayStationsDistanceText(stationDistance?.text || '');
        const stationDistanceValue = `${stationDistance?.value ? (stationDistance?.value / 1000).toFixed(0) : ''}`;
        formMethods.setValue('distance', distance || stationDistanceValue, {
          shouldDirty: true,
          shouldTouch: true,
        });
      });
    }
  };

  const resetStation = () => {
    formMethods.setValue('stationTextField', '', {
      shouldDirty: true,
      shouldTouch: true,
    });
    formMethods.setValue('station', '', {
      shouldDirty: true,
      shouldTouch: true,
    });
  };

  const resetReturnStation = () => {
    formMethods.setValue('returnStationTextField', '', {
      shouldDirty: true,
      shouldTouch: true,
    });
    formMethods.setValue('returnStation', '', {
      shouldDirty: true,
      shouldTouch: true,
    });
  };

  useEffect(() => {
    if (fieldValues.oneWay) {
      setFilteredOneWayStations(
        fieldValues.category === getTranslatedCategory('pkw') ? oneWayStationsCar
          : fieldValues.category === getTranslatedCategory('lkw') ? oneWayStationsTruck : stations
      );
      if (fieldValues.station === fieldValues.returnStation) {
        resetReturnStation();
      }
      if (fieldValues.station) {
        getAvailableReturnStations();
      }
    } else {
      setFilteredOneWayStations(stations);
    }
  }, [fieldValues.oneWay, fieldValues.category, stations]);

  useEffect(() => {
    if (filteredOneWayStations.length > 0
      && fieldValues.station
      && !filteredOneWayStations.find((station) => station.id === fieldValues.station)
      && !presetStation
    ) {
      resetStation();
      resetReturnStation();
      setError('station', {message: t('stationNotAvailableForOneWay')})
      setOneWayStationsDistanceText('');
      formMethods.setValue('distance','', {
        shouldDirty: true,
        shouldTouch: true,
      });
    }
    if (filteredOneWayReturnStations.length > 0
      && fieldValues.returnStation
      && !filteredOneWayReturnStations.find((station) => station.id === fieldValues.returnStation)
      && !presetReturnStation
    ) {
      resetReturnStation();
      setOneWayStationsDistanceText('');
      formMethods.setValue('distance','', {
        shouldDirty: true,
        shouldTouch: true,
      });
    }
  }, [filteredOneWayStations, filteredOneWayReturnStations]);

  return {
    formMethods,
    onSubmit,
    emergencyOnPointerDownHandler,
    control,
    errors,
    fieldValues,
    stationsById,
    isDesktop,
    starcarDateRangePickerState,
    stations,
    bprRef,
    presetStation,
    getAvailableReturnStations,
    isLoadingReturnStations,
    filteredOneWayStations,
    filteredOneWayReturnStations,
    setOneWayStationsDistance,
    setOneWayStationsDistanceText,
    oneWayStationsDistanceText
  };
};
