import {useTranslation} from "next-i18next";
import {Control, useFormContext} from "react-hook-form";
import useCustomController from "hooks/useCustomController";
import {Checkbox, FormControlLabel} from "@mui/material";
import {palette} from "styles/mui/scss";
import React from "react";
import {withErrorBoundary} from "components/error/ErrorBoundary";
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import { useGetTranslatedCategory } from "hooks/useGetTranslatedCategory";

const FormInputOneWay = ({
  control,
  checkboxColor = palette.colorWhite,
  labelColor = palette.colorWhite,
  checkboxSize = 'medium',
  hideOneWayForCategoryIfStationDoesntSupportIt
}: {
  control: Control<any, any>;
  checkboxColor?: string;
  labelColor?: string;
  checkboxSize?: 'medium' | 'small';
  hideOneWayForCategoryIfStationDoesntSupportIt?: boolean;

}) => {
  const [t] = useTranslation('bpr');
  const {
    field: {onChange, value, onBlur, name, ref},
  } = useCustomController({control, name: 'oneWay'});
  const {watch} = useFormContext();
  const category = watch('category');
  const getTranslatedCategory = useGetTranslatedCategory()
  const checkedIcon = <CheckBoxOutlinedIcon fontSize={checkboxSize} />;
  return (
    <FormControlLabel
      sx={{
        color: labelColor,
        display: 'inline',
        alignItems: 'flex-start',
        margin: 0,
        '& .MuiFormControlLabel-label': {lineHeight: '24px', fontSize: {xxs: '10px', xl: '11px'}},
      }}
      control={
        hideOneWayForCategoryIfStationDoesntSupportIt ? <div></div> : <Checkbox
          sx={{
            padding: 0,
            marginRight: '5px',
            color: checkboxColor,
            '&.Mui-checked': {
              color: checkboxColor,
            },
          }}
          ref={ref}
          onChange={onChange}
          checked={value}
          onBlur={onBlur}
          name={name}
          size={checkboxSize}
          checkedIcon={checkedIcon}
        />
      }
      label={hideOneWayForCategoryIfStationDoesntSupportIt ? category === getTranslatedCategory('pkw') ? t('stationNotAvailableForOneWayCar') : t('stationNotAvailableForOneWayTruck') : t('isOneWay')}
    />
  );
};

export default withErrorBoundary(FormInputOneWay, 'FormInputOneWay');