import {PlacesServiceContext} from 'context/PlacesServiceContext';
import {Controller, useForm} from 'hooks/custom-react-hook-form';
import {useTranslation} from 'hooks/translations/useCustomTranslation';
import {useContext, useEffect, useState} from 'react';

import {Box, Checkbox, FormControlLabel, Typography} from '@mui/material';

import {Station} from 'lib/api/backend.schemas';

import getDistanceFromLatLonInKm from 'utils/maps/getDistanceFromLatLonInKm';
import {GOOGLE_PRIVACY_POLICIES} from 'utils/maps/loadMaps';

import {Button} from 'components/basic-components';
import {CustomIcon} from 'components/basic-components/CustomIcon/CustomIcon';
import {NextLink} from 'components/basic-components/NextLink/Link';
import {ConsentContext} from 'components/section-components/ConsentManager';

import StationGmRadiusListElement from './StationGmRadiusListElement';
import styles from './styles/stationSelectionFlyout.module.scss';
import {ICoordinates, IStationWithDistance} from './types';

const StationGmRadius = ({
  setNearbyStations,
  stations,
  textFieldValue,
  setFocusOninput = () => {},
}: {
  setNearbyStations: (stations: IStationWithDistance[]) => void;
  stations: Station[];
  textFieldValue: string;
  setFocusOninput?: Function;
}) => {
  const {t} = useTranslation(['bpr', 'common']);
  const [coordinates, setCoordinates] = useState({} as ICoordinates);
  const [open, setOpen] = useState(false);

  const {getDetails, getPlacePredictions, initialized, init} = useContext(PlacesServiceContext);
  const [placePredictions, setPlacePredictions] = useState([]);

  useEffect(() => {
    if (placePredictions) {
      setOpen(true);
    }
  }, [placePredictions]);

  useEffect(() => {
    setNearbyStations([]);
    setOpen(false);
  }, [textFieldValue]);

  const fetchForInput = () => {
    if (!initialized) {
      init().then(() => {
        setCoordinates({} as ICoordinates);
        getPlacePredictions({input: textFieldValue, componentRestrictions: {country: 'de'}})
          .then(({predictions}) => setPlacePredictions(predictions))
          .catch(() => {});
      });
    } else {
      setCoordinates({} as ICoordinates);
      getPlacePredictions({input: textFieldValue, componentRestrictions: {country: 'de'}})
        .then(({predictions}) => setPlacePredictions(predictions))
        .catch(() => {});
    }
  };

  const getLocationForId = id => {
    setOpen(false);
    setCoordinates({} as ICoordinates);
    getDetails({
      placeId: id,
    })
      .then((location: ICoordinates) => setCoordinates(location))
      .then(() => {
        setFocusOninput();
      });
  };

  useEffect(() => {
    if ((coordinates?.lat, coordinates?.lng)) {
      const filteredStations: IStationWithDistance[] = stations
        .map(({latitude, longitude, ...station}) => {
          return {
            distance: getDistanceFromLatLonInKm(
              latitude,
              longitude,
              coordinates.lat,
              coordinates.lng,
            ),
            latitude,
            longitude,
            ...station,
          };
        })
        .filter(station => station.distance < 100)
        .sort((a, b) => {
          return a.distance - b.distance;
        });
      setNearbyStations(filteredStations);
    } else {
      setNearbyStations([]);
    }
  }, [coordinates]);
  const {
    state: {maps},
    setConsentStateForKey,
  } = useContext(ConsentContext);

  const {control, handleSubmit} = useForm({
    name: 'StationGmRadius',
    defaultValues: {mapsAccepted: maps},
  });

  const onButtonClick = handleSubmit(({mapsAccepted}) => {
    if (mapsAccepted) {
      setConsentStateForKey('maps', true);
    }
    if (textFieldValue.length >= 1) {
      fetchForInput();
    }
  });
  const RadiusDisclaimer = () => {
    if (maps) return null;
    return (
      <Box className={styles.radiusDisclaimerWrapper}>
        <Typography className={styles.title}>{t('searchNearby?')}</Typography>
        <Typography className={styles.infoText}>{t('mapsUsageInfo')}</Typography>
        <Typography className={styles.link}>
          <NextLink
            style={{color: 'inherit'}}
            href={GOOGLE_PRIVACY_POLICIES}
            target="_blank"
            rel="noreferrer"
          >
            {t('learnMore', {ns: 'common'})}
          </NextLink>
        </Typography>
      </Box>
    );
  };
  return (
    <Box sx={{marginBottom: '20px', display: textFieldValue.length > 0 ? 'block' : 'none'}}>
      <RadiusDisclaimer />
      <Box sx={{padding: '0 20px'}}>
        <Button
          sx={{marginBottom: '20px'}}
          onClick={onButtonClick}
        >
          {t('searchNearby')}
        </Button>
        {!maps ? (
          <Controller
            name="mapsAccepted"
            control={control}
            render={({field: {name, onChange, value}}) => {
              return (
                <FormControlLabel
                  control={
                    <Checkbox
                      name={name}
                      onChange={onChange}
                      checked={value}
                      sx={{'& *': {color: 'black'}}}
                      icon={<CustomIcon name="checkbox" className={styles.icon} />}
                    />
                  }
                  label={t('alwaysAllowSearchNearby')}
                />
              );
            }}
          />
        ) : null}
      </Box>
      <Box component="ul" className={styles.list} sx={{display: open ? 'block' : 'none'}}>
        <Typography className={styles.placesTitle}>{t('places')}</Typography>
        {placePredictions.map(pred => (
          <StationGmRadiusListElement
            description={pred.description}
            key={pred.place_id}
            onClick={() => getLocationForId(pred.place_id)}
          />
        ))}
      </Box>
    </Box>
  );
};

export default StationGmRadius;
